// This component allows users to update the room status of the given location
import { useState, useEffect } from "react";
import GlobalVars from "../../../../Config";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export default function RoomStatus({
    saveTriggered,
    setSaveTriggered,
    setOpenModuleNotification,
    jobID
}) {
    const [availRoomStatus, setAvailRoomStatus] = useState([]);
    const [selectedRoomStatus, setSelectedRoomStatus] = useState(null);

    const [isPosted, setIsPosted] = useState(false);  // if the posting is submitted

    // query for available room statuses
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/room-status-change-item?organizationID=" + sessionStorage.getItem("organizationId");
        fetch(url, {
            method: "GET",
            headers: {
                Authorization: sessionStorage.getItem("idToken"),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                setAvailRoomStatus(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);
        

    const handleStatusChange = (status) => {
        setSelectedRoomStatus(status);

        // set the status of the available room statuses
        setAvailRoomStatus(
            availRoomStatus.map((roomStatus) => {
                if (roomStatus.status_code === status.status_code) {
                    return {
                        ...roomStatus,
                        selected: true,
                    };
                }
                return {
                    ...roomStatus,
                    selected: false,
                };
            })
        );

    };

    // handle save triggered event
    useEffect(() => {
        if (saveTriggered && selectedRoomStatus) {
            setOpenModuleNotification({
                "display": true,
                "type": "loading",
            })
        

            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/room-status-change";
            let data = {
                jobID: jobID,
                roomStatus: selectedRoomStatus.status_code,
                organizationID: sessionStorage.getItem("organizationId"),
                userID: localStorage.getItem("userid"),
            };
            
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("idToken"),
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("Success:", data);
                    setOpenModuleNotification({
                        "display": true,
                        "type": "success",
                    })
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setOpenModuleNotification({
                        "display": true,
                        "type": "fail",
                    })
                })
                .finally(() => {
                    setSaveTriggered(false);
                });
        }
    }, [saveTriggered]);

    // check if the posting is submitted
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/room-status-change" + "?jobID=" + jobID;
        fetch(url, {
            method: "GET",
            headers: {
                Authorization: sessionStorage.getItem("idToken"),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                setIsPosted(data.isPosted);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    return (
        <div className="">
            <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                        房間狀態已曾提交
                    </p>
                    </div>
                </div>
            </div>
            {availRoomStatus.map((status) => (
                <button
                    key={status.status_code}
                    onClick={() => handleStatusChange(status)}
                    className={`flex flex-col items-center justify-left w-full px-4 py-2 mt-2 text-sm font-medium border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                        status.selected
                            ? "bg-indigo-700 text-white border-transparent"
                            : "bg-white text-black border-gray-300 hover:bg-gray-100"
                    }`}
                >
                    <span className="text-lg font-bold">{status.status_code}</span>
                    <span className={`ml-2 text-xs text-gray-500${
                        status.selected ? " text-white" : ""
                    }`}>{status.description}</span>
                </button>
            ))}
        </div>
    );
}
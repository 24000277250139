// import libraries
import PreviewSelectedOptions from "../PreviewSelectedOption";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";

export default function JobTypeComponent({
    typeList,
    types,
    setTypes,
}) {
    const [searchResult, setSearchResult] = useState([]);
    const searchResultRef = useRef(searchResult);

    const filterOptions = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

      

    return (
        <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
                工作要求
            </label>
            <p
            className='text-xs text-red-500'
            >必填欄</p>
            <div className="mt-2">
                <Select
                    closeMenuOnSelect={false}
                    options={typeList}
                    isMulti
                    className="text-sm cursor-pointer"
                    value={types}
                    onChange={(value) => {
                        setTypes(value);
                    }}
                    placeholder="選擇工作"
                    filterOption={filterOptions}
                />
                {/* info tag that show how many seleceted */}
                <p className="text-xs text-gray-500 mt-1 flex items-center">
                <div
                    className='ml-1'
                >
                    <PreviewSelectedOptions options={types} />
                </div>
                </p>
            </div>
            <p className="mt-2 text-sm text-red-500 hidden" >
                不能為空
            </p>
        </div>
    );
}
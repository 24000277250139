import {
    useState,
    useEffect,
    Fragment
} from 'react';
import {
    Transition,
    Dialog,
} from '@headlessui/react';
import {
    BellAlertIcon
} from '@heroicons/react/20/solid';
import { getMessaging, getToken } from 'firebase/messaging'
import GlobalVars from '../../Config';

// request for permission to get notification
export default function NotificationPermissionDialog() {
    const [open, setOpen] = useState(false)
  
    useEffect(() => {
      let lastUpdateTime = localStorage.getItem("lastFCMTokenUpdateTime");
    
      // if it's null or before today, update the token by checking date, month, year
      if (!lastUpdateTime || (new Date().getTime() - lastUpdateTime) > 86400000 * 5) {
        setOpen(true);
      }
    }, []);
  
  
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <BellAlertIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        通知權限
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          請按鍵允許通知，以便我們能及時通知您。
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setOpen(false)
                        CheckAndUpdateFirebaseToken()
                      }}
                    >
                      允許通知
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

function CheckAndUpdateFirebaseToken() {
    localStorage.setItem("lastFCMTokenUpdateTime", new Date().getTime());

    const messaging = getMessaging();
    // get the current token
    Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            getToken(messaging, {vapidKey: GlobalVars.firebase_publick_fcm_key})
              .then((currentToken) => {
              if (currentToken) {
                  // create POST request to verify FCM token from /api/v1/users/fcm
                  let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/fcm";
                  let body = {
                      "fcmToken": currentToken,
                      "userID": localStorage.getItem("userid"),
                      "device": navigator.userAgent
                  };
                  let headers = {
                  "Content-Type": "application/json"
                  };

                  fetch(url, {
                      method: "POST",
                      body: JSON.stringify(body),
                      headers: headers
                  }).then(response => {
                      if (response.status === 200) {
                      console.log("FCM token updated successfully");
                      } else {
                      console.log("Error: " + response.status);
                      }
                  });

              } else {
                  console.log('No registration token available. Request permission to generate one.');
              }
              }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
              });
        } else {
        console.log('Permission not granted for notifications.');
        }
    });

    return 
}

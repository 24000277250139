import ApplicationShell from '../../Components/ApplicationShell';
import { useState, useEffect, Fragment, useContext } from 'react';
import GlobalVars from '../../Config';
import SlideOvers from '../../Components/SlideOvers';
import { Menu, Transition } from '@headlessui/react'
import { 
  EllipsisVerticalIcon,
  BellIcon as BellIconSolid
} from '@heroicons/react/20/solid'
import {
  BellIcon
} from '@heroicons/react/24/outline'
import {MultiSelect} from "react-multi-select-component";
import React from 'react';
import NewUser from './Component/NewUserForm';
import { UserContext } from '../../Context';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// fetch request to get user list
const fetchUserList = (setPeople) => {
  // fetch data from backend
  let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/organization/users?organizationID=' + sessionStorage.getItem("organizationId");
        
  fetch(url, {
    headers: {
      "Authorization": sessionStorage.getItem("idToken")
    }
  })
  .then(response => response.json())
  .then(data => {
      // setting email key for meta.email and delete meta.email
      data['users'].forEach((user) => {
          user['email'] = user['meta']['email'];
          delete user['meta'];

          // convert displayName to name
          user['name'] = user['displayName'];
          delete user['displayName'];
          
          user['show'] = true;
      });

      setPeople(data['users']);

      // query for users latest status
      QueryUserLatestStatus({setPeople}, data['users']);
  })

}

// component for user list
function UserList({people, setPeople, handleEditClickUser, handleDeleteClickUser}) { 
    // fetch data from backend
    useEffect(() => {
        fetchUserList(setPeople);
    }, []);

    // change status of user between active and inactive
    const handleStatusChange = (e) => {
        let userId = e.target.getAttribute('data-person');
        let status = e.target.getAttribute('data-status');
        
        // PUT request to /api/v1/users/status to change status
        let submitData = {
            "userID": userId,
            "status": status === 'active' ? 'inactive' : 'active',
            "organizationID": sessionStorage.getItem("organizationId")
        };

        fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/users/status', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                alert("更改成功, 現在為你更新頁面資料");
                fetchUserList(setPeople);
            })
            .catch((error) => {
                alert("更改失敗");
                console.error('Error:', error);
            });
            
    };

    // handle test notify users
    const handleTestNotify = (e) => {
      // prompt users for confirmation
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/notification/push';
      fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "body": "測試訊息",
            "userID": e.target.getAttribute('data-person'),
            "organizationID": sessionStorage.getItem("organizationId")
          }),
          })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(data => {
              console.log('Success:', data);
              alert("提送成功");
          })
          .catch((error) => {
              alert("不能提送");
              console.error('Error:', error);
          });

    }

    // handle users copy registration link. when users click the button a url will be copied to clipboard
    const handleUserCopyRegistrationLink = (person) => {
      const registrationLink = `${window.location.origin}/create-account?organizationid=${sessionStorage.getItem("organizationId")}&email=${person.email}&name=${person.name}`;

      // replace empty space with %20
      const replacedLink = registrationLink.replace(/ /g, "%20");
  
      navigator.clipboard.writeText(replacedLink)
        .then(() => {
          alert("已複製用戶登記連結到你的剪貼簿");
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });

    }

    return (
        <ul role="list" className="divide-y divide-gray-100">
          {people.map((person) => (
            <li key={person.email} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <a href={person.href} className="hover:underline flex items-center">
                      {person.name}
                      {
                        person.AllowNotify ? 
                        (
                          <>
                            <BellIconSolid className="ml-1 h-5 w-5 text-yellow-400" aria-hidden="true" />
                          </>
                        ) : (
                          <BellIcon className="ml-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        )
                      }
                      
                    </a>
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <a href={`mailto:${person.email}`} className="truncate hover:underline">
                      {person.email}
                    </a>
                    <span className="mx-1">|</span>
                    <span className="text-gray-700">{person._id}</span>
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-6">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">{person.role}</p>
                  {person.lastSeen ? (
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
                    </p>
                  ) : (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className={`flex-none rounded-full ${person.status === 'active' ? 'bg-emerald-500/20' : 'bg-gray-500/20' } p-1`}>
                        <div className={`h-1.5 w-1.5 rounded-full ${person.status === 'active' ? 'bg-emerald-500' : 'bg-gray-500' } `} />
                      </div>
                      <p className="text-xs leading-5 text-gray-500">{person.status === 'active' ? '已啟動' : '未啟動'}</p>
                    </div>
                  )}
                </div>
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={handleEditClickUser}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                            )}
                            data-person={person._id}
                          >
                            修改<span className="sr-only">, {person.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            發送短信<span className="sr-only">, {person.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            data-person={person._id}
                            onClick={handleTestNotify}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                            )}
                          >
                            測試提醒
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                            )}
                            data-person={person._id}
                            data-status={person.status}
                            onClick={handleStatusChange}
                          >
                            {person.status === 'active' ? '停用' : '啟動' }<span className="sr-only">, {person.name}</span>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                            )}
                            data-person={person._id}
                            onClick={handleDeleteClickUser}
                          >
                            刪除
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                            )}
                            data-person={person._id}
                            onClick={() => handleUserCopyRegistrationLink(person)}
                          >
                            複製用戶登記連結
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
    )
}

// component for user type list
function UserTypeList({handleEditClickUserType, handleDeleteClickUserType}) {
  const [typeList, setTypeList] = useState([{name: '工作人員', description: '工作人員'}]);

  // fetch data from backend
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-types?organizationID=' + sessionStorage.getItem("organizationId");
    
    fetch(url)
    .then(response => response.json())
    .then(data => {
        
        setTypeList(data['types']);
    }
  )}, []);

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {typeList.map((type) => (
        <li key={type._id} className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                <a  className="hover:underline">
                  {type.name}
                </a>
              </p>
              {/* <p className="mt-1 flex text-xs leading-5 text-gray-500">
                <a href={`mailto:${person.email}`} className="truncate hover:underline">
                  {person.email}
                </a>
                <span className="mx-1">|</span>
                <span className="text-gray-700">{person._id}</span>
              </p> */}
            </div>
          </div>
          <div className="flex shrink-0 items-center gap-x-6">
            {/* <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">{person.role}</p>
              {person.lastSeen ? (
                <p className="mt-1 text-xs leading-5 text-gray-500">
                  Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
                </p>
              ) : (
                <div className="mt-1 flex items-center gap-x-1.5">
                  <div className={`flex-none rounded-full ${person.status === 'active' ? 'bg-emerald-500/20' : 'bg-gray-500/20' } p-1`}>
                    <div className={`h-1.5 w-1.5 rounded-full ${person.status === 'active' ? 'bg-emerald-500' : 'bg-gray-500' } `} />
                  </div>
                  <p className="text-xs leading-5 text-gray-500">{person.status === 'active' ? 'Active' : 'Inactive'}</p>
                </div>
              )}
            </div> */}
            <Menu as="div" className="relative flex-none">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={handleEditClickUserType}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                        )}
                        data-type={type._id}
                      >
                        修改
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        data-type={type._id}
                        onClick={handleDeleteClickUserType}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                        )}
                      >
                        刪除
                      </a>
                    )}
                  </Menu.Item>
                  {/* <Menu.Item>
                    {({ active }) => (
                      <button
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                        )}
                        data-person={person._id}
                        data-status={person.status}
                        onClick={handleStatusChange}
                      >
                        {person.status === 'active' ? '停用' : '啟動' }<span className="sr-only">, {person.name}</span>
                      </button>
                    )}
                  </Menu.Item> */}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </li>
      ))}
    </ul>
  )
}

// component for user group list 
function UserGroupList({handleEditClickUserGroup, handleDeleteClickUserGroup}) {
  const [groupList, setGroupList] = useState([{name: '工作人員', description: '工作人員'}]);

  // fetch data from backend
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-groups?organizationID=' + sessionStorage.getItem("organizationId");
    
    fetch(url)
    .then(response => response.json())
    .then(data => {
        
        setGroupList(data['groups']);
    }
  )}, []);

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {groupList.map((group) => (
        <li key={group._id} className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                <a  className="hover:underline">
                  {group.name}
                </a>
              </p>
              {/* <p className="mt-1 flex text-xs leading-5 text-gray-500">
                <a href={`mailto:${person.email}`} className="truncate hover:underline">
                  {person.email}
                </a>
                <span className="mx-1">|</span>
                <span className="text-gray-700">{person._id}</span>
              </p> */}
            </div>
          </div>
          <div className="flex shrink-0 items-center gap-x-6">
            {/* <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">{person.role}</p>
              {person.lastSeen ? (
                <p className="mt-1 text-xs leading-5 text-gray-500">
                  Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
                </p>
              ) : (
                <div className="mt-1 flex items-center gap-x-1.5">
                  <div className={`flex-none rounded-full ${person.status === 'active' ? 'bg-emerald-500/20' : 'bg-gray-500/20' } p-1`}>
                    <div className={`h-1.5 w-1.5 rounded-full ${person.status === 'active' ? 'bg-emerald-500' : 'bg-gray-500' } `} />
                  </div>
                  <p className="text-xs leading-5 text-gray-500">{person.status === 'active' ? 'Active' : 'Inactive'}</p>
                </div>
              )}
            </div> */}
            <Menu as="div" className="relative flex-none">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={handleEditClickUserGroup}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                        )}
                        data-type={group._id}
                      >
                        修改
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        data-group={group._id}
                        onClick={handleDeleteClickUserGroup}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                        )}
                      >
                        刪除
                      </a>
                    )}
                  </Menu.Item>
                  {/* <Menu.Item>
                    {({ active }) => (
                      <button
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                        )}
                        data-person={person._id}
                        data-status={person.status}
                        onClick={handleStatusChange}
                      >
                        {person.status === 'active' ? '停用' : '啟動' }<span className="sr-only">, {person.name}</span>
                      </button>
                    )}
                  </Menu.Item> */}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </li>
      ))}
    </ul>
  )
}
      

// query for users latest status
function QueryUserLatestStatus({setPeople}, people) {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users/latest-status?organizationID=' + sessionStorage.getItem("organizationId");

    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
        .then(response => response.json())  
        .then(data => {
            let statuses = data['statuses'];

            // Create a new array with the updated people
            let updatedPeople = people.map(person => {
              let updatedPerson = { ...person }; // Create a copy of the person object


              statuses.forEach(status => {
                  if (updatedPerson['_id'] === status['assignedTo']) {
                      updatedPerson['location'] = status['locationName'];
                      updatedPerson['lastSeenDateTime'] = status['latestStatus']['changedAt'];

                      // if it's within 3 hours, show difference in hours, but if it's within 1 hour, show difference in minutes
                      let now = new Date();
                      let lastSeen = new Date(updatedPerson['lastSeenDateTime']);
                      let diff = now - lastSeen;
                      let diffHours = Math.round(diff / 1000 / 60 / 60);
                      let diffMinutes = Math.round(diff / 1000 / 60);
                      
                      if (diffHours <= 3 && diffHours >= 1) {
                          updatedPerson['lastSeen'] = diffHours + ' 小時前';
                      } else if (diffMinutes <= 60) {
                          updatedPerson['lastSeen'] = diffMinutes + ' 分鐘前';
                      } else {
                          updatedPerson['lastSeen'] = null;
                      }
                  }
              });

              return updatedPerson; // Return the updated person object
            });

            // Update the people state with the new array
            setPeople(updatedPeople);
            
            // retrieve fcmToken from backend
            RetrieveFCMTokens({setPeople});
        });
}

// fetch fcmToken from backend
function RetrieveFCMTokens({setPeople}) {
  let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users/fcm?organizationID=' + localStorage.getItem("organizationId");

  fetch(url)
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    } 
    return response.json()
  })
  .then(data => {
    let fcmTokens = data['fcmTokens']  // it's an object with userID as keys and each key contain a key named allowNotify

    // go through each users in people and set AllowNotify based on fcm
    setPeople(prevPeople => {
      const updatedPeople = prevPeople.map(person => {
        if (fcmTokens[person._id]) {
          return { ...person, AllowNotify: fcmTokens[person._id]['allowNotify'] };
        } else {
          return { ...person, AllowNotify: false };
        }
      });
      return updatedPeople;
    });
  })
}

// get users detail and set to state
const getUserDetail = (userId, setUserId, setEmail, setName, setType, setGroup, setPhone, setUserRole) => {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users?userID=' + userId + '&organizationID=' + sessionStorage.getItem("organizationId");

    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      let user = data['user'];

      // set email
      setEmail(user['meta']['email']);

      // set phone
      setPhone(user['meta']['phone']);

      // set name
      setName(user['displayName']);

      // set type
      if (user['typeID'] === undefined) {
        setType('');
      } else {
        setType(user['typeID']);
      }

      // set group
      if (user['groupID'] === undefined) {
        setGroup('');
      } else {
        setGroup(user['groupID']);
      }

      // set userId
      setUserId(user['_id']);

      // set user role if exists
      if (user['role']) {
        setUserRole(user['role']);
      } else {
        setUserRole('');
      }

    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

// main component
export default function UserListPage() {

    const {globalUserRole} = useContext(UserContext);

    // states for sidebar
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarTitle, setSidebarTitle] = useState('新增用戶');   

    // states for edit mode
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState('');

    // states for tabs
    const [tabs, setTabs] = useState([
      { name: '用戶', href: 'user', current: true },
      { name: '用戶類別', href: 'user-type', current: false },
      { name: '用戶群組', href: 'user-group', current: false },
    ]);
    const [tabName, setTabName] = useState('用戶');

    // states for new users
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [group, setGroup] = useState('');
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState('');
    const [userRole, setUserRole] = useState('');

    // states for user group
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [userListForUserGroup, setUserListForUserGroup] = useState([]);
    const [selectedUserListForUserGroup, setSelectedUserListForUserGroup] = useState([]);
    const [selectedTypeForUserGroup, setSelectedTypeForUserGroup] = useState([]);

    // states for user type
    const [typeName, setTypeName] = useState(''); 
    const [typeDescription, setTypeDescription] = useState('');
    const [selectedMainTypeList, setSelectedMainTypeList] = useState([]);

    // check for url argument for id and open edit tab
    useEffect(() => {
      let urlParams = new URLSearchParams(window.location.search);
      let userId = urlParams.get('id');
      if (userId && userId !== "") {
          setSidebarOpen(true);
          setEditMode(true);
          setEditId(userId);

          // get user detail
          getUserDetail(userId, setUserId, setEmail, setName, setType, setGroup, setPhone, setUserRole);
      }
  }, []);

    // update tab names with current tab name
    useEffect(() => {
        let currentTab = tabs.find((tab) => tab.current).name;
        setTabName(currentTab);
    }, [tabs]); 

    // based on the current url, set the current tab
    useEffect(() => {
        // check if there's a url argument named t
        let urlParams = new URLSearchParams(window.location.search);
        let tabName = urlParams.get('t');

        // skip if tabName is null
        if (tabName === null || tabs.some(tab => tab.current && tab.href === tabName)) {
            return;
        }

        // set current tab based on url
        // setTabs(tabs.map((tab) => {
        //     if (tabName === tab.href) {
        //         console.log(tab.href);
        //         tab.current = true;
        //     } else {
        //         tab.current = false;
        //     }
        //     return tab;
        // }));
        setTabs((prevTabs) => prevTabs.map((tab) => ({
          ...tab,
          current: tabName === tab.href
      })));
    }, []);

    // set document title
    document.title = '用戶';

    const [people, setPeople] = useState([{
        name: 'Leslie Alexander',
        email: 'leslie.alexander@example.com',
        role: 'Co-Founder / CEO',
        imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        href: '#',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    }]); 

    // submit new users
    const submitUserData = (e) => {
        e.preventDefault();

        // get organization id
        const organizationID = sessionStorage.getItem("organizationId");

        // create submittion data
        let data = {
            typeID: type,
            groupID: group,
            email: email,
            phone: phone,
            organizationID: organizationID,
            preRegistration: true,
            displayName: name,
            role: userRole
        }

        // set api method 
        let apiMethod = '';
        if (editMode) {
            apiMethod = 'PUT';
            data['userID'] = editId;
        } else {
            apiMethod = 'POST';
        }

        // post data to backend
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users';
        fetch(url, {
            method: apiMethod,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem("idToken")
            },
            body: JSON.stringify(data),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                if (editMode) {
                  alert("修改成功");
                } else {
                  alert("新增成功");
                }
                setSidebarOpen(false);

                // redirect the page to 
                window.location.href = '/user-list';

            })
            .catch((error) => {
                if (!editMode) {
                  alert("新增失敗");
                } else {
                  alert("修改失敗");
                }
                console.error('Error:', error);
            });
    }

    // submit new user group
    const submitNewUserGroup = (e) => {
        // prompt users that selected users will be added to selected user type
        if (!window.confirm("已選擇的用戶將會根據所選的用戶類別進行分類，確定要繼續嗎?")) {
            return;
        }

        e.preventDefault();

        // get organization id
        const organizationID = sessionStorage.getItem("organizationId");

        // create submittion data
        let data = {
            name: groupName,
            description: groupDescription,
            organizationID: organizationID,
            userList: selectedUserListForUserGroup,
            typeID: selectedTypeForUserGroup[0]
        }

        // update api method based on edit mode
        let apiMethod = 'POST';
        if (editMode) {
            apiMethod = 'PUT';
            data['groupID'] = editId;
        }

        // post data to backend
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-groups';
        fetch(url, {
            method: apiMethod,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                alert("新增成功");
                setSidebarOpen(false);

                // redirect the page to
                window.location.href = '/user-list?t=user-group';
            })
            .catch((error) => {
                alert("新增失敗");
                console.error('Error:', error);
            });
    }

    // submit new user type
    const submitNewUserType = (e) => {
        e.preventDefault();

        // get organization id
        const organizationID = sessionStorage.getItem("organizationId");

        // create submittion data
        let data = {
            name: typeName,
            description: typeDescription,
            organizationID: organizationID,
            mainTypeIDs: selectedMainTypeList
        }

        // change api method based on edit mode
        let apiMethod = 'POST';
        if (editMode) {
            apiMethod = 'PUT';
            data['typeID'] = editId;
        }           

        // post data to backend
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-types';
        fetch(url, {
            method: apiMethod,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                alert("新增成功");
                setSidebarOpen(false);

                // redirect the page to
                window.location.href = '/user-list?t=user-type';
            })
            .catch((error) => {
                alert("新增失敗");
                console.error('Error:', error);
            });
      }

    
      // filter existing locations 
    
    const queryAction = (txt) => {
        let results = 0;
        // Create a new array with updated locations
        const updatedPeople = people.map((person) => {
            if (person.name.includes(txt)) {
                results++;
                return {...person, show: true};
            } else {
                return {...person, show: false};
            }
        });

        // update locationList state
        setPeople(updatedPeople);

        console.log("results: ", results);
    };

    // handle edit button click 
    const handleEditClickUser = (e) => {
        setEditMode(true);

        let userId = e.target.getAttribute('data-person');
        setEditId(userId);
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users?userID=' + userId;

        // set sidebar title
        setSidebarTitle('修改用戶');

        // set sidebar open
        setSidebarOpen(true);

        // fetch data from backend
        getUserDetail(userId, setUserId, setEmail, setName, setType, setGroup, setPhone, setUserRole);
      
    };

    // handle delete button click for user
    const handleDeleteClickUser = (e) => {
      // prompt users for confirmation
      if (!window.confirm("確定要刪除嗎?")) {
        return;
      }

      let userId = e.target.getAttribute('data-person');
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users';

      let submitData = {
        "organizationID": sessionStorage.getItem("organizationId"),
        "userID": userId
      }

      fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem("idToken")
        },
        body: JSON.stringify(submitData),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            alert("刪除成功");
            window.location.reload();
        })
        .catch((error) => {
            alert("刪除失敗");
            console.error('Error:', error);
        });
    };

    // handle edit button click for user type
    const handleEditClickUserType = (e) => {
      let typeId = e.target.getAttribute('data-type');
      setEditId(typeId);
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-types?typeID=' + typeId;

      // set edit mode
      setEditMode(true);
      
      // fetch data from backend  
      fetch(url)
      .then(response => response.json())
      .then(data => {
          let type = data['type'];

          // set sidebar title
          setSidebarTitle('修改用戶類別');

          // set sidebar open
          setSidebarOpen(true);

          // set name
          setTypeName(type['name']);

          // set main type list
          setSelectedMainTypeList(type['mainTypeIDs']);

          // set description
          setTypeDescription(type['description']);
      })
      .catch((error) => {
        console.error('Error:', error);
        alert("未能獲取用戶類別資料")
      });
    };

    // handle delete button click for user type
    const handleDeleteClickUserType = (e) => {
      // prompt users for confirmation
      if (!window.confirm("確定要刪除嗎?")) {
        return;
      }

      let typeId = e.target.getAttribute('data-type');
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-types';

      let submitData = {
        "organizationID": sessionStorage.getItem("organizationId"),
        "typeID": typeId
      }

      fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            alert("刪除成功");
            window.location.reload();
        })
        .catch((error) => {
            alert("刪除失敗");
            console.error('Error:', error);
        });
    };

    // handle edit button click for user group
    const handleEditClickUserGroup = (e) => {
      let groupId = e.target.getAttribute('data-type');
      setEditId(groupId);
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-groups?groupID=' + groupId;
      
      // set edit mode
      setEditMode(true);

      fetch(url)
      .then(response => response.json())
      .then(data => {
          let group = data['group'];

          // set sidebar title
          setSidebarTitle('修改用戶群組');

          // set sidebar open
          setSidebarOpen(true);

          // set name
          setGroupName(group['name']);

          // set description
          setGroupDescription(group['description']);

          // fetch user list for user group with group id
          let url2 = GlobalVars.BACKEND_DOMAIN + '/api/v1/users?groupID=' + groupId;
          fetch(url2, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
          .then(response => response.json())
          .then(data => {
              let users = data['users'];

              let selectedOption = users.map((user) => {
                return {
                  value: user._id,
                  label: user.displayName
                }
              });

              // set user list for user group
              setSelectedUserListForUserGroup(selectedOption);
          })
          
          // set selected type for user group
          setSelectedTypeForUserGroup([group['typeID']]);
      });
    };

    // handle delete button click for user group
    const handleDeleteClickUserGroup = (e) => {
      // prompt users for confirmation
      if (!window.confirm("確定要刪除嗎?")) {
        return;
      }

      let groupId = e.target.getAttribute('data-group');
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-groups';

      let submitData = {
        "organizationID": sessionStorage.getItem("organizationId"),
        "groupID": groupId
      }

      fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            alert("刪除成功");
            window.location.reload();
        })
        .catch((error) => {
            alert("刪除失敗");
            console.error('Error:', error);
        });
    };

    return (
        <>
            <ApplicationShell queryAction={queryAction} >
                <PageTabs tabs={tabs} setTabs={setTabs} />
                {tabName === '用戶' &&
                  <div>
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            用戶列表
                            </h2>
                        </div>
                        
                        <div className="mt-4 flex md:ml-4 md:mt-0">
                          {globalUserRole === 'admin' &&
                            <button
                            type="button"
                            className="ml-0 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                                setSidebarOpen(true);
                                setSidebarTitle('新增用戶');
                                setEditMode(false);
                                setEmail('');
                                setName('');
                                setType('');
                                setGroup('');
                                setUserRole('');
                            }}
                            >
                            新增
                            </button>
                          }
                        </div>
                    </div>
                    <div className="mt-4 flex ">
                        <RoleStats />
                    </div>
                    <UserList people={people} setPeople={setPeople} handleEditClickUser={handleEditClickUser} handleDeleteClickUser={handleDeleteClickUser} />
                  </div>
                }
                { tabName === '用戶類別' &&
                  <div>
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            用戶類別列表
                            </h2>
                        </div>
                        <div className="mt-4 flex md:ml-4 md:mt-0">
                            <button
                            type="button"
                            className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                              setSidebarOpen(true);
                              setSidebarTitle('新增用戶類別');
                              setEditMode(false);
                            }}
                            >
                            新增
                            </button>
                        </div>
                    </div>
                    <UserTypeList handleEditClickUserType={handleEditClickUserType} handleDeleteClickUserType={handleDeleteClickUserType} />
                  </div>
                }
                { tabName === '用戶群組' &&
                  <div>
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            用戶群組列表
                            </h2>
                        </div>
                        <div className="mt-4 flex md:ml-4 md:mt-0">
                            <button
                            type="button"
                            className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                              setSidebarOpen(true);
                              setSidebarTitle('新增用戶群組');
                              setEditMode(false);
                              setSelectedUserListForUserGroup([]);
                            }}
                            >
                            新增
                            </button>
                        </div>
                    </div>
                    <UserGroupList handleEditClickUserGroup={handleEditClickUserGroup} handleDeleteClickUserGroup={handleDeleteClickUserGroup} />
                  </div>
                }
            </ApplicationShell>
            { tabName === '用戶' &&
              <SlideOvers setOpen={setSidebarOpen} open={sidebarOpen} title={sidebarTitle} content={
                <NewUser 
                  email={email} 
                  setEmail={setEmail} 
                  type={type} 
                  setType={setType} 
                  group={group} 
                  setGroup={setGroup} 
                  name={name}
                  setName={setName}
                  phone={phone}
                  setPhone={setPhone}
                  userRole={userRole}
                  setUserRole={setUserRole}
                />} submitAction={submitUserData} />
            }
            { tabName === '用戶類別' &&
              <SlideOvers setOpen={setSidebarOpen} open={sidebarOpen} title={sidebarTitle} content={<NewUserType name={typeName} setName={setTypeName} description={typeDescription} setDescription={setTypeDescription}  setSelectedMainTypeList={setSelectedMainTypeList} selectedMainTypeList={selectedMainTypeList} />} submitAction={submitNewUserType} />
            }
            { tabName === '用戶群組' &&
              <SlideOvers setOpen={setSidebarOpen} open={sidebarOpen} title={sidebarTitle} content={
                <NewUserGroup 
                  name={groupName} 
                  setName={setGroupName} 
                  description={groupDescription} 
                  setDescription={setGroupDescription} 
                  userListForUserGroup={userListForUserGroup} 
                  setUserListForUserGroup={setUserListForUserGroup} 
                  selectedUserListForUserGroup={selectedUserListForUserGroup}
                  setSelectedUserListForUserGroup={setSelectedUserListForUserGroup}
                  selectedTypeForUserGroup= {selectedTypeForUserGroup}
                  setSelectedTypeForUserGroup={setSelectedTypeForUserGroup}
                />} submitAction={submitNewUserGroup} />
            }
        </>
    )
}

// role counting in user list. Show the count of each role (e.g. admin, member, and viewer)
function RoleStats() {
  const [roleStats, setRoleStats] = useState({
    "admin": 0,
    "member": 0,
    "viewer": 0
  });

  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/user/role-counting?organizationID=" + sessionStorage.getItem("organizationId");
    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
    .then(response => response.json())
    .then(data => {
      setRoleStats(data['roleCount']);
    })
  }, []);

  return(
    <div className="flex items-center gap-x-2">
      <span className="text-sm text-gray-500">管理員: {roleStats.admin}</span>
      <span className="text-sm text-gray-500">用戶: {roleStats.member}</span>
      <span className="text-sm text-gray-500">檢視者: {roleStats.viewer}</span>
    </div>
  )
}

// page tabs
function PageTabs({tabs, setTabs}) {
    
  const handleTabSelection = (e) => {
    // check if the target is a select element or not
    let selectedOption = ""
    if (e.target.tagName !== 'SELECT') {
      selectedOption = e.target.innerHTML;
    } else {
      selectedOption = e.target.value;
    }

    // update the current tab
    let newTabs = tabs.map((tab) => {
        if (tab.name === selectedOption) {
            return {
                name: tab.name,
                href: tab.href,
                current: true,
            }
        } else {
            return {
                name: tab.name,
                href: tab.href,
                current: false,
            }
        }
    });

    setTabs(newTabs);
  }

  return (
      <div className="mb-10">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
            onChange={handleTabSelection}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={handleTabSelection}
                  className={classNames(
                    tab.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
}


// new user type form
function NewUserType({name, setName, description, setDescription, setSelectedMainTypeList, selectedMainTypeList}) {

    // handle name change
    function handleNameChange(e) {
        setName(e.target.value);
    }

    // handle description change
    function handleDescriptionChange(e) {
        setDescription(e.target.value);
    }

    return (
        <>
            <label 
            htmlFor="name"
            className='block text-sm font-medium text-gray-700'
            >
                名稱:
                </label>
            <input 
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required />

            <label 
            htmlFor="description"
            className='block text-sm font-medium text-gray-700 mt-4'
            >描述:</label>
            <textarea
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required></textarea>
            <label 
            htmlFor="mainType"
            className='block text-sm font-medium text-gray-700 mt-4'
            >工作類別:</label>
            <MainTypeList setSelectedMainTypeList={setSelectedMainTypeList} selectedMainTypeList={selectedMainTypeList} />
        </>
    )
  }


  // new user group form 
function NewUserGroup({name, setName, description, setDescription, userListForUserGroup, setUserListForUserGroup, selectedUserListForUserGroup, setSelectedUserListForUserGroup, selectedTypeForUserGroup,  setSelectedTypeForUserGroup}) {

    // handle name change
    function handleNameChange(e) {
        setName(e.target.value);
    }

    // handle description change
    function handleDescriptionChange(e) {
        setDescription(e.target.value);
    }

    // fetch user list from backend for the organization 
    useEffect(() => {
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users?organizationID=' + sessionStorage.getItem("organizationId");
      fetch(url, {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
      .then(response => response.json())
      .then(data => {
          let users = data['users'];

          setUserListForUserGroup(users);
      })
    }, []);

    return (
        <>
            <label 
            htmlFor="name"
            className='block text-sm font-medium text-gray-700'
            >
                名稱:
                :</label>
            <input 
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required />

            <label 
            htmlFor="description"
            className='block text-sm font-medium text-gray-700 mt-4'
            >描述:</label>
            <textarea
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required></textarea>
            <label
            htmlFor="userList"
            className='block text-sm font-medium text-gray-700 mt-4 mb-2'
            >用戶:</label>
            <UserListForUserGroup userListForUserGroup={userListForUserGroup} selectedUserListForUserGroup={selectedUserListForUserGroup} setSelectedUserListForUserGroup={setSelectedUserListForUserGroup} />
            <label
              htmlFor="type"
              className='block text-sm font-medium text-gray-700 mt-4'
            >用戶類別:</label>
            <UserTypeListForUserGroup setSelectedMainTypeList={setSelectedTypeForUserGroup} selectedMainTypeList={selectedTypeForUserGroup} />
        </>
    )
}

// a checkbox list with a main type list to associate user type with main type jobs 
function MainTypeList({setSelectedMainTypeList, selectedMainTypeList}) {
  const [typeList, setTypeList] = useState([{name: '工作人員', description: '工作人員'}]);

  // fetch data from backend
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/maintypes?organizationID=' + sessionStorage.getItem("organizationId");
    fetch(url)
      .then(response => response.json())
      .then(rawData => {
          let data = rawData['types']

          // convert langVar.tc to name 
          data.forEach((type) => {
            type['name'] = type['langVar']['tc'] + ' (' + type['name'] + ')';
          });

          setTypeList(data);
      })
      .catch((error) => {
        alert("未能獲取工作類別資料");
        console.error('Error:', error);
      });
  }, []);

  // populate box if edit mode
  useEffect(() => {
    if (selectedMainTypeList.length === 0 || typeList.length === 0) {
      return;
    } else {
      for (let i = 0; i < selectedMainTypeList.length; i++) {

        let checkbox = document.querySelector(`input[data-type="${selectedMainTypeList[i]}"]`);
        if (checkbox) {
          checkbox.checked = true;
        }
      }
    }

  }, [selectedMainTypeList, typeList]);

  // change list in setSelectedMainTypeList when users select or unselect a checkbox
  function handleCheckboxChange(e) {
    if (e.target.checked) {
      setSelectedMainTypeList(prevState => [...prevState, e.target.getAttribute('data-type')]);
    } else {
      setSelectedMainTypeList(prevState => prevState.filter(type => type !== e.target.getAttribute('data-type')));
    }
  }

  return (
    <fieldset>
      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
        {typeList.map((type, personIdx) => (
          <div key={personIdx} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor={`${type._id}`} className="select-none font-medium text-gray-900">
                {type.name}
              </label>
            </div>
            <div className="ml-3 flex h-6 items-center">
              <input
                id={`${type._id}`}
                name={`${type._id}`}
                data-type={type._id}
                onChange={handleCheckboxChange}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

// checkbox list for selecting user in user group tab 
function UserListForUserGroup({userListForUserGroup, selectedUserListForUserGroup, setSelectedUserListForUserGroup}) {

  // convert userList to options
  const [options, setOptions] = useState([]);
  useEffect(() => {
    // return if it's empty
    if (userListForUserGroup == undefined || userListForUserGroup.length === 0) {
      return;
    }

    let options = userListForUserGroup.map((user) => {
      return {label: user.displayName, value: user._id};
    });

    setOptions(options);
  }, [userListForUserGroup]);

  return (
    <>
      {/* <pre>{JSON.stringify(selected)}</pre> */}
      <MultiSelect
        options={options}
        value={selectedUserListForUserGroup}
        onChange={setSelectedUserListForUserGroup}
        labelledBy={"Select"}
        
      />
    </>
  )
}

// a checkbox list with a main type list to associate user type with main type jobs 
function UserTypeListForUserGroup({setSelectedMainTypeList, selectedMainTypeList}) {
  const [typeList, setTypeList] = useState([{name: '工作人員', description: '工作人員'}]);

  // fetch data from backend
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/user-types?organizationID=' + sessionStorage.getItem("organizationId");
    fetch(url)
      .then(response => response.json())
      .then(rawData => {
          let data = rawData['types']

          setTypeList(data);
      })
      .catch((error) => {
        alert("未能獲取工作類別資料");
        console.error('Error:', error);
      });
  }, []);

  // populate box if edit mode
  useEffect(() => {

    if (selectedMainTypeList.length === 0 || typeList.length === 0) {
      return;
    } else {
      for (let i = 0; i < selectedMainTypeList.length; i++) {

        let checkbox = document.querySelector(`input[data-type="${selectedMainTypeList[i]}"]`);
        if (checkbox) {
          checkbox.checked = true;
        }
      }
    }

  }, [selectedMainTypeList, typeList]);

  // change list in setSelectedMainTypeList when users select or unselect a checkbox
  function handleCheckboxChange(e) {
    if (e.target.checked) {
      setSelectedMainTypeList(prevState => [...prevState, e.target.getAttribute('data-type')]);
    } else {
      setSelectedMainTypeList(prevState => prevState.filter(type => type !== e.target.getAttribute('data-type')));
    }
  }

  return (
    <fieldset>
      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
        {typeList.map((type, personIdx) => (
          <div key={personIdx} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor={`${type._id}`} className="select-none font-medium text-gray-900">
                {type.name}
              </label>
            </div>
            <div className="ml-3 flex h-6 items-center">
              <input
                id={`${type._id}`}
                name={`${type._id}`}
                data-type={type._id}
                onChange={handleCheckboxChange}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}
